import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private http: HttpClient) {}

  addClient(clientData: any): Observable<any> {
    return this.http.post<any>(environment.host + '/client.php', {
      gofor: 'addClient',
      client: {
        ...clientData,
      },
    });
  }

  viewClient(clientId: string): Observable<any> {
    return this.http.get<any>(environment.host + '/client.php', {
      params: new HttpParams({
        fromObject: {
          gofor: 'viewClient',
          id: clientId,
        },
      }),
    });
  }

  getClient(clientId: string): Observable<any> {
    return this.http.get<any>(environment.host + '/client.php', {
      params: new HttpParams({
        fromObject: {
          gofor: 'getClient',
          id: clientId,
        },
      }),
    });
  }

  updateClient(clientId: string, clientData: any): Observable<any> {
    return this.http.put<any>(environment.host + '/client.php', {
      gofor: 'updateClient',
      id: clientId,
      client: {
        ...clientData,
      },
    });
  }

  deleteClient(clientId: string) {
    return this.http.put<any>(environment.host + '/client.php', {
      gofor: 'deleteClient',
      id: clientId,
    });
  }

  getAllClients(payload: any): Observable<any[]> {
    return this.http.post<any[]>(environment.host + '/client.php', {
      gofor: 'getAllClients',
      ...payload,
    });
  }

  getClientList(): Observable<any> {
    return this.http.post<any>(environment.host + '/client.php', {
      gofor: 'getClientList',
    });
  }
}
