import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, concatMap, EMPTY } from 'rxjs';
import { ClientService } from '../service/api/client/client.service';
import { ToastrService } from 'ngx-toastr';
import { Navigate } from '@ngxs/router-plugin';

export interface clientmodel {
  item: any;
  id: string | null;
  list: any[];
}

export class AddClient {
  static readonly type = '[AddClient] ClientState';
  constructor(public payload: any) {}
}

export class UpdateClient {
  static readonly type = '[UpdateClient] ClientState';
  constructor(public payload: any) {}
}

export class GetClient {
  static readonly type = '[GetClient] ClientState';
  constructor(public id: string) {}
}

export class FetchClients {
  static readonly type = '[FetchClients] ClientState';
}

export class ViewClient {
  static readonly type = '[ViewClient] ClientState';
  constructor(public id: string) {}
}

export class DeleteClient {
  static readonly type = '[DeleteClient] ClientState';
  constructor(public id: string) {}
}

@State<clientmodel>({
  name: 'client',
  defaults: {
    list: [],
    id: null,
    item: null,
  },
})
@Injectable()
export class ClientState {
  constructor(
    private clientService: ClientService,
    private toastService: ToastrService
  ) {}

  @Selector()
  static getList(state: clientmodel): any[] {
    return state.list;
  }

  @Selector()
  static getItem(state: clientmodel): any {
    return state.item;
  }

  @Action(AddClient)
  addClient(states: StateContext<clientmodel>, action: AddClient) {
    return this.clientService.addClient(action.payload).pipe(
      concatMap((res) => {
        console.log(res);
        this.toastService.success(res.message, '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          closeButton: true,
        });
        return states.dispatch([new Navigate(['/', 'home', 'client'])]);
      }),
      catchError((error) => {
        console.log(error);
        return EMPTY;
      })
    );
  }

  @Action(UpdateClient)
  updateClient(states: StateContext<clientmodel>, action: UpdateClient) {
    let id = states.getState().id as string;
    return this.clientService.updateClient(id, action.payload).pipe(
      concatMap((res) => {
        console.log(res);
        this.toastService.success(res.message, '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          closeButton: true,
        });
        return states.dispatch([new Navigate(['/', 'home', 'client'])]);
      }),
      catchError((error) => {
        console.log(error);
        return EMPTY;
      })
    );
  }

  @Action(GetClient)
  editClient(states: StateContext<clientmodel>, action: GetClient) {
    return this.clientService.getClient(action.id).pipe(
      concatMap((res) => {
        console.log(res);
        states.patchState({
          item: res.message,
          id: action.id,
        });
        return EMPTY;
      })
    );
  }

  @Action(ViewClient)
  fetchClientById(states: StateContext<clientmodel>, action: ViewClient) {
    return this.clientService.viewClient(action.id).pipe(
      concatMap((res) => {
        console.log(res);
        states.patchState({
          item: res.message,
        });
        return EMPTY;
      })
    );
  }

  @Action(DeleteClient)
  deletClient(states: StateContext<clientmodel>, action: ViewClient) {
    return this.clientService.deleteClient(action.id).pipe(
      concatMap((res) => {
        console.log(res);
        this.toastService.success(res.message, '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          closeButton: true,
        });
        return states.dispatch([new Navigate(['/', 'home', 'client'])]);
      }),
      catchError((error) => {
        console.log(error);
        return EMPTY;
      })
    );
  }

  @Action(FetchClients)
  fetchClients(states: StateContext<clientmodel>, action: FetchClients) {
    return this.clientService.getClientList().pipe(
      concatMap((res) => {
        console.log(res);
        states.patchState({
          list: res.message.clientList,
        });
        return EMPTY;
      })
    );
  }
}
